<template>
  <v-container v-if="this.season" fluid>
    <v-card elevation="0">
      <v-toolbar
          color="white"
          flat
      >
        <v-btn
            icon
            light
            link
            to="/seasons"
        >
          <v-icon color="grey darken-2">
            mdi-arrow-left
          </v-icon>
        </v-btn>
        <v-toolbar-title class="grey--text text--darken-4">
          Gegevens seizoen
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="save">
          Bewaren
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <v-row>
          <v-col md="12" sm="12">
            <v-text-field
                v-model="season.Name"
                label="Naam"
                outlined
                required
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col md="6" sm="12">
            <v-menu
                ref="menu1"
                v-model="menu1"
                :close-on-content-click="false"
                min-width="auto"
                offset-y
                transition="scale-transition"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    v-model="date1"
                    v-bind="attrs"
                    v-on="on"
                    append-icon="mdi-calendar"
                    hide-details
                    label="Start seizoen"
                    outlined
                    readonly
                    v-on:click:append="menu1=true"
                ></v-text-field>
              </template>
              <v-date-picker
                  v-model="season.From"
                  min="1950-01-01"
                  @change="saveFrom"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col md="6" sm="12">
            <v-menu
                ref="menu2"
                v-model="menu2"
                :close-on-content-click="false"
                min-width="auto"
                offset-y
                transition="scale-transition"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    v-model="date2"
                    v-bind="attrs"
                    v-on="on"
                    append-icon="mdi-calendar"
                    hide-details
                    label="Einde seizoen"
                    outlined
                    readonly
                    v-on:click:append="menu2=true"
                ></v-text-field>
              </template>
              <v-date-picker
                  v-model="season.Till"
                  min="1950-01-01"
                  @change="saveTill"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col md="6" sm="12">
            <v-card outlined>
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                  <tr>
                    <th class="text-left">
                      Accomodatie
                    </th>
                    <th class="text-left">
                      Prijs
                    </th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr
                      v-for="item in pricing"
                      :key="item.ID"
                  >
                    <td>{{ item.Name }}</td>
                    <td>
                      <v-text-field v-model="item.Price"  />
                    </td>
                  </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import {Season} from "@/models/season";
import {SeasonService} from "@/services/season-service";
import {SeasonPricing} from "@/models/season-pricing";

const {AccomodationService} = require("@/services/accomodation-service");
export default {
  data() {
    return {
      menu1: false,
      menu2: false,
      season: new Season(),
      pricing: Array[SeasonPricing] = []
    }
  },
  computed: {
    date1() {
      let t = new Date(this.season.From);
      return t.toLocaleDateString("nl-NL", {
        weekday: 'short',
        timeZone: 'UTC'
      }) + ", " + t.getDate() + " " + t.toLocaleString("nl-NL", {
        month: 'short',
        timeZone: 'UTC'
      }) + " " + t.getFullYear();
    },
    date2() {
      let t = new Date(this.season.Till);
      return t.toLocaleDateString("nl-NL", {
        weekday: 'short',
        timeZone: 'UTC'
      }) + ", " + t.getDate() + " " + t.toLocaleString("nl-NL", {
        month: 'short',
        timeZone: 'UTC'
      }) + " " + t.getFullYear();
    }
  },
  methods: {
    saveFrom($event) {
      this.season.From = new Date($event);
      this.menu1 = false;
    },
    saveTill($event) {
      this.season.Till = new Date($event);
      this.menu2 = false;
    },
    async save(){
      await SeasonService.updateSeason(this.season);
      await SeasonService.updateSeasonPricing(this.pricing);
    }
  },
  async created() {
    if (this.$route.params.id) {
      this.season = await SeasonService.single(this.$route.params.id)
      this.pricing = await SeasonService.pricing(this.$route.params.id);
    }
  }
}
</script>