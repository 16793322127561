import {Pricing} from "@/models/pricing";
import {AccomodationOption} from "@/models/accomodation-option";
import {AccomodationCost} from "@/models/accomodation-cost";

export class Accomodation {
	ID: number = 0;
	Name: string = "";
	Qty: number = 0;
	MaxGuests: number = 0;
	MaxChildren: number = 0;
	FreeGuestsIncluded: number = 0;
	ExtraGuestCost: number = 0;
	ExtraChildCost: number = 0;
	ExtraBabyCost: number = 0;
	Size: string = "";
	Description: string = "";
	EmailDescription: string = "";
	Pricing: Pricing[] = [];
	Options: AccomodationOption[] = [];
	Costs: AccomodationCost[] = [];

	public constructor(data?: any) {
		if (!data) return;
		this.ID = data.id;
		this.Name = data.name;
		this.Qty = data.qty;
		this.MaxGuests = data.max_guests;
		this.MaxChildren = data.max_children;
		this.FreeGuestsIncluded = data.free_guests_included;
		this.ExtraGuestCost = data.extra_guest_cost;
		this.ExtraBabyCost = data.extra_baby_cost;
		this.ExtraChildCost = data.extra_child_cost;
		this.Size = data.size;
		this.Description = data.description;
		this.EmailDescription = data.email_description;
		if (data.pricing) {
			this.Pricing = data.pricing.map((p: Pricing) => {
				return new Pricing(p)
			});
		}
		if (data.options) {
			this.Options = data.options.map((p: AccomodationOption) => {
				return new AccomodationOption(p)
			});
		}
		if (data.costs) {
			this.Costs = data.costs.map((p: AccomodationCost) => {
				return new AccomodationCost(p)
			});
		}
	}

}