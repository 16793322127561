import {Axios} from "@/utils/axios";
import {Season} from "@/models/season";
import {SeasonPricing} from "@/models/season-pricing";


export class SeasonServiceClass {

	public url: string = "https://booking-api.webcode.be/api"

	async get() {
		try {
			let response = await Axios.get(this.url + "/seasons");
			if (response.status == 200) {
				return response.data.data.map((a:any) => new Season(a));
			}
		} catch (e) {

		}
	}

	async single(id:number) {
		try {
			let response = await Axios.get(this.url + "/season/"+id);
			if (response.status == 200) {
				return new Season(response.data.data);
			}
		} catch (e) {

		}
	}

	async pricing(id:number) {
		try {
			let response = await Axios.get(this.url + "/season/pricing/"+id);
			if (response.status == 200) {
				return response.data.data.map((a:any) => new SeasonPricing(a));
			}
		} catch (e) {

		}
	}

	async updateSeason(season:Season) {
		try {
			let response = await Axios.put(this.url + "/seasons",{season});
			if (response.status == 200) {
				return response.data.data.map((a:any) => new Season(a));
			}
		} catch (e) {

		}
	}

	async updateSeasonPricing(pricing:SeasonPricing){
		try {
			let response = await Axios.put(this.url + "/seasons/pricing",{pricing});
			if (response.status == 200) {
				return response.data.data.map((a:any) => new SeasonPricing(a));
			}
		} catch (e) {

		}
	}

}

export const SeasonService = new SeasonServiceClass();