import {Axios} from "@/utils/axios";
import {Accomodation} from "@/models/accomodation";


export class AccomodationServiceClass {

	public url: string = "https://booking-api.webcode.be/api"

	async get() {
		try {
			let response = await Axios.get(this.url + "/accomodations");
			if (response.status == 200) {
				return response.data.data.map((a:any) => new Accomodation(a));
			}
		} catch (e) {

		}
	}

	async single(id:number) {
		try {
			let response = await Axios.get(this.url + "/accomodation/"+id);
			if (response.status == 200) {
				return new Accomodation(response.data.data);
			}
		} catch (e) {

		}
	}

	async insert(a:Accomodation){
		try {
			let response = await Axios.post(this.url + "/accomodations",{a});
			if (response.status == 200) {
				return new Accomodation(response.data.data);
			}
		} catch (e) {

		}
	}

	async update(a:Accomodation){
		try {
			let response = await Axios.put(this.url + "/accomodations",{a});
			if (response.status == 200) {
				return new Accomodation(response.data.data);
			}
		} catch (e) {

		}
	}

}

export const AccomodationService = new AccomodationServiceClass();