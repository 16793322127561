

export class AccomodationOption {
	ID: number = 0;
	Name: string = "";
	Price: number = 0;
	Multiply: Boolean = false;
	Available: number = 0;
	Qty: number = 0;

	public constructor(data?:any) {
		if (!data) return;
		this.ID = data.id ? data.id : data.ID;
		this.Name = data.name ? data.name : data.Name;
		this.Price = data.price ? data.price : data.Price;
		this.Multiply = data.multiply ? data.multiply : data.Multiply;
		this.Available = data.available ? parseInt(data.available) : parseInt(data.Available);
		this.Qty = data.qty ? (data.qty == true ? 1 : parseInt(data.qty)) : (data.Qty ? data.Qty : 0);
	}

}