export class SeasonPricing {
	ID: number = 0;
	Name: string = ""
	IDSeason: number = 0;
	IDAccomodation: number = 0;
	Price: number = 0;
	ExtraGuest: number = 0;
	ExtraChild: number = 0;


	public constructor(data?:any) {
		if (!data) return;
		this.ID = data.id;
		this.Name = data.name;
		this.IDSeason = data.id_season;
		this.IDAccomodation = data.id_accomodation;
		this.Price = data.price;
		this.ExtraChild = data.extra_child;
		this.ExtraGuest = data.extra_guest;
	}

}